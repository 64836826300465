import firebase from "../firebase";
import "firebase/compat/firestore";
const db = firebase.firestore();
export default {
  methods: {
    fetchUsersByGroup(group) {
      if (group) {
        let that = this;
        // group.users = []
        const promises = [];
        var localMember = [];
        if (group?.members) {
          Object.keys(group.members).forEach((key) => {
            if (group.members[key]) {
              localMember.push(key);
            }
          });
        }

        localMember.forEach((member) => {
          const promise = new Promise((resolve, reject) => {
            db.collection("users")
              .doc(String(member))
              .get()
              .then(function (doc) {
                if (doc.exists) {
                  // eslint-disable-next-line no-console
                  resolve(doc.data());
                } else {
                  //User not found and make user Guest
                  const guest = {
                    displayName: member + "guest",
                    email: member + "guest@gmail.com",
                    photoURL: null,
                    type: "client",
                    uid: Number(member),
                  };
                  that.saveUser(guest);
                  resolve(guest);
                }
              })
              .catch(function (error) {
                reject(error);
              });
          });
          promises.push(promise);
        });
        return Promise.all(promises);
      }
    },
    saveUser(user) {
      // eslint-disable-next-line no-console
      const userRef = db.collection("users");
      userRef.doc(String(user.uid)).set({
        uid: Number(user.uid),
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        type: user.type,
        phone: user.phone,
      });
    },
    updateUser(user) {
      this.saveUser(user);
    },
    checkUserExisted(user) {
      const docRef = db.collection("users").doc(String(user.uid));
      return new Promise((resolve, reject) => {
        docRef
          .get()
          .then(function (doc) {
            resolve(doc.exists);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    fetchUsers() {
      const vm = this;
      return new Promise((resolve, reject) => {
        db.collection("users")
          .get()
          .then(function (querySnapshot) {
            const allUsers = [];
            querySnapshot.forEach((doc) => {
              const user = doc.data();
              allUsers.push(user);
            });
            // vm.chatUsers = allUsers
            vm.$store.commit("prepChatUser/addChatMembers", allUsers);
            resolve(allUsers.length > 0);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
      });
    },
    getUserFromFireStore(user) {
      if (!user) return;
      const docRef = db.collection("users").doc(String(user.uid));
      return new Promise((resolve) => {
        docRef.onSnapshot(function (doc) {
          resolve(doc.data());
        });
      });
    },
  },
};
